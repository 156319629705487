import React from "react"
import styled from "styled-components"
import PostTagList from "./PostTagList"
const StyledWrapper = styled.div`
  &.post-brief {
    .post-brief__author {
      margin-bottom: 8px;
    }
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const PostBrief = ({ author, date, tags }) => {
  return (
    <StyledWrapper className="post-brief">
      <div className="post-brief__author">
        <span>{`${author} on ${date}`}</span>
      </div>
      <div>
        <PostTagList tags={tags} />
      </div>
    </StyledWrapper>
  )
}

export default PostBrief
