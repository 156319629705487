import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/ListLayout"
import SEO from "../components/seo"
import Post from "../components/Post"
import Title from "../components/Title"

const TagsPage = ({data, pageContext, location}) => {
  const {tag} = pageContext
  const {totalCount, edges} = data.allMarkdownRemark
  const pageHeader = `${tag} - ${totalCount} 篇`
  return (
    <Layout>
      <SEO title="標籤" pathname={location.pathname} />
      <Title>{pageHeader}</Title>
      <ul>
        {edges.map(edge => {
          return (
            <Post
              key={edge.node.id}
              title={edge.node.frontmatter.title}
              date={edge.node.frontmatter.date}
              author={edge.node.frontmatter.author}
              slug={edge.node.fields.slug}
              fluid={edge.node.frontmatter?.image?.childImageSharp?.fluid}
              tags={edge.node.frontmatter.tags}
              showImg={false}
            />
          )
        })}
      </ul>
    </Layout>
  )
}

export const tagQuery = graphql`
  query($tag: String!) {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {tags: {in: [$tag]}}, fields: {draft: {eq: false}}}
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD ddd")
            author
            tags
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default TagsPage
