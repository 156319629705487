import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { CardTitle, CardBody } from "reactstrap"
import Img from "gatsby-image"
import styled from "styled-components"
import { lighten } from "polished"
import PostBrief from "../components/Post/PostBrief"

const StyledLink = styled(Link)`
  color: ${props => props.theme.titleFontColor};
  &:hover {
    color: ${props => lighten(0.2, props.theme.titleFontColor)};
    text-decoration: none;
  }
`

const StyledWrapper = styled.div`
  &.post-com {
    box-shadow: rgba(46, 41, 51, 0.08) 0px 1px 2px,
      rgba(71, 63, 79, 0.08) 0px 2px 4px;
    background-color: #ffffff;
    margin-bottom: 1.5rem;
    margin-left: 0rem;
    margin-right: 0rem;
    border-radius: 4px;
    border-width: 1px;
    &:hover {
      transform: translateY(-0.25rem);
      box-shadow: rgba(46, 41, 51, 0.08) 0px 4px 8px,
        rgba(71, 63, 79, 0.16) 0px 8px 16px;
    }
  }
`


const Post = ({ title, author, slug, date, tags }) => {
  return (
    <StyledWrapper className="post-com">
      <StyledLink to={slug}>
        <CardBody>
          <CardTitle>
            <h2>{title}</h2>
          </CardTitle>
          <PostBrief author={author} date={date} tags={tags} />
        </CardBody>
      </StyledLink>
    </StyledWrapper>
  )
}

export default Post
