import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Tag as TagIcon } from "react-feather"
import { slugify_chinese } from "../../util/utilityFunctions"

const StyledWrapper = styled.div`
  &.post-tag-list {
    color: ${props => { return props.theme.tagColor }};
    .post-tag-list__header {
      display: inline-block;
      line-height: 1.5;
      margin-right: 10px;
    }
    .post-tag-list__nav {
      padding: 0;
      display: inline-flex;
      list-style: none;

      .post-tag-list__item {
        margin-right: 10px;

        &__tag {
          border-width: 1px;
          border-style: solid;
          border-color: ${props => props.theme.tagColor};
          border-radius: 4px;
          padding: 3px 5px;
          /* color: #1f7872; */
          color: ${props => props.theme.tagColor};
        }

        > a {
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
`

const PostTagList = ({ tags }) => {
  return (
    <StyledWrapper className="post-tag-list">
      <div className="post-tag-list__header">
        <TagIcon size="24" />
        <strong>Tags</strong>
      </div>
      <ul className="post-tag-list__nav">
        {tags &&
          tags.map(tag => {
            return (
              <li className="post-tag-list__item" key={tag}>
                <Link to={`/tag/${slugify_chinese(tag)}`}>
                  <div className="post-tag-list__item__tag">{tag}</div>
                </Link>
              </li>
            )
          })}
      </ul>
    </StyledWrapper>
  )
}

export default PostTagList
